import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import React from "react";
import useStartMotion from "../../hooks/use-start-motion";
import Title from "../../components-v2/Base/Title";
import { productsWithLinks } from "../../data/whitepapers/restaurants-hub";
import ProductCard from "../../components-v2/Base/ProductCard";

const slideVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Products = () => {
  const { ref, controls } = useStartMotion();

  return (
    <motion.section
      className="max-w-1440 w-full px-4 lg:px-10 md:mt-10"
      ref={ref}
      initial={isMobileOnly ? "visible" : "hidden"}
      variants={slideVariants}
      animate={controls}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <Title
        className={`
          text-center lg:text-left
          mx-auto xl:mx-0
          xl:ml-28
          md:max-w-70-percent xl:max-w-[45%]`}
        level={2}
        withBlueSymbol
      >
        {productsWithLinks.title}
      </Title>

      <div
        className={`grid max-w-[1122px] mx-auto
        grd-cols-1 md:grid-cols-2 xl:grid-cols-4
        gap-x-8 gap-y-12 place-items-center`}
      >
        {productsWithLinks.products.map((item) => (
          <ProductCard
            key={item.title}
            {...item}
            smallFont
            darkenImage
            openInNewTab
          />
        ))}
      </div>
    </motion.section>
  );
};

export default Products;
